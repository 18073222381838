import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import axios from "../../../api/axios";
import * as FileSaver from "file-saver";

function ChefListRamadanSlim() {
  const [date, setDate] = useState(dayjs());
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  async function downloadOrderListDetails() {
    try {
      setLoading(true);
      const response = await axios.post(
        "order/ramadanslim/chef-list/",
        {
          date: dayjs(date).format("YYYY-MM-DD"),
        },
        {
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        FileSaver.saveAs(
          blob,
          "chef_ramadan_slim_" + dayjs(date).format("DD-MM-YYYY") + ".xlsx"
        );
      } else {
        console.log(response.data.msg);
        setAlertOpen(true);
        setAlertMessage(response.data.msg);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setAlertMessage(String(error));
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") return;
    setAlertOpen(false);
  };

  // Function to disable Fridays
  const shouldDisableDate = (date) => {
    return date.getDay() === 5; // 5 corresponds to Friday
  };

  const body = (
    <div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h6" gutterBottom>
        Download Ramadan Slim Chef List Order for Kitchen:
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          label="Date"
          value={date}
          onChange={(newDate) => setDate(newDate)}
          shouldDisableDate={shouldDisableDate}
          fullWidth
          renderInput={(props) => (
            <TextField sx={{ mt: 2, width: 220 }} {...props} fullWidth />
          )}
        />
      </LocalizationProvider>
      <br />
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={downloadOrderListDetails}
        disabled={loading}
      >
        Download
      </Button>
    </div>
  );

  return body;
}

export default ChefListRamadanSlim;