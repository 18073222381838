import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import SellTwoToneIcon from "@mui/icons-material/SellTwoTone";
import { LogoutTwoTone } from "@mui/icons-material";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import FoodBankTwoToneIcon from "@mui/icons-material/FoodBankTwoTone";
import CelebrationTwoToneIcon from "@mui/icons-material/CelebrationTwoTone";
import UserManagementIcon from "@mui/icons-material/SupervisorAccount";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DrawerHeader from "@mui/material/Toolbar"; // reusing Toolbar for header
import logo from "../static/images/scale logo.svg";
import { useNavigate } from "react-router-dom";

const drawerWidth = 320;
const miniDrawerWidth = 60;

export default function Layout({ title, body }) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [hovered, setHovered] = useState(false);

  // Determine actual drawer state - expanded when hovered or not collapsed
  const isExpanded = hovered || !collapsed;

  // Helper to render a menu item; when collapsed, only the icon is displayed.
  const renderMenuItem = (key, text, icon, onClick) => (
    <ListItem button key={key} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      {isExpanded && <ListItemText primary={text} />}
    </ListItem>
  );

  // Expansion delay management
  useEffect(() => {
    let timeoutId;
    if (!hovered) {
      timeoutId = setTimeout(() => {
        // Only collapse if the user specifically set it to collapsed
        if (collapsed) {
          document.body.style.setProperty('--drawer-width', `${miniDrawerWidth}px`);
        }
      }, 300); // Small delay before collapsing to make the transition smoother
    } else {
      document.body.style.setProperty('--drawer-width', `${drawerWidth}px`);
    }
    return () => clearTimeout(timeoutId);
  }, [hovered, collapsed]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${isExpanded ? drawerWidth : miniDrawerWidth}px)`,
          ml: `${isExpanded ? drawerWidth : miniDrawerWidth}px`,
          transition: 'all 0.3s',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          width: isExpanded ? drawerWidth : miniDrawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isExpanded ? drawerWidth : miniDrawerWidth,
            boxSizing: "border-box",
            transition: "width 0.3s",
            overflowX: "hidden",
          },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
          {isExpanded && (
            <Box sx={{ display: "flex", alignItems: "left" }}>
              <img alt="Scale Kuwait" src={logo} height="25" />
              <Typography variant="h5" sx={{ ml: 2 }}>
                Scale Admin
              </Typography>
            </Box>
          )}
          <IconButton onClick={() => setCollapsed(!collapsed)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          {renderMenuItem("Dashboard", "Dashboard", <DashboardTwoToneIcon color="primary" />, () =>
            navigate("/dashboard")
          )}
          {renderMenuItem("Users", "Users", <GroupTwoToneIcon color="primary" />, () =>
            navigate("/users")
          )}
          {renderMenuItem("Buy Subscription", "Buy Subscription", <ShoppingCartTwoToneIcon color="primary" />, () =>
            navigate("/event-selection")
          )}
          {renderMenuItem("Order Lists", "Order Lists", <ReceiptLongTwoToneIcon color="primary" />, () =>
            navigate("/order-list-page")
          )}
          {renderMenuItem("Meals", "Meals", <FoodBankTwoToneIcon color="primary" />, () =>
            navigate("/meals-page")
          )}
          {renderMenuItem("Coupons", "Coupons", <SellTwoToneIcon color="primary" />, () =>
            navigate("/coupon-list")
          )}
          {renderMenuItem("Events", "Events", <CelebrationTwoToneIcon color="primary" />, () =>
            navigate("/events")
          )}
          {renderMenuItem("User Management", "⚒️ User Management", <UserManagementIcon color="primary" />, () =>
            navigate("/user-management")
          )}
          {renderMenuItem("Kitchen Management", "⚒️ Kitchen Management", <RestaurantMenuIcon color="primary" />, () =>
            navigate("/kitchen-management")
          )}
          <ListItem
            button
            key={"Logout"}
            onClick={() => {
              localStorage.setItem("isAuthenticated", "false");
              navigate("/");
            }}
            sx={{ mt: 5 }}
          >
            <ListItemIcon>
              <LogoutTwoTone color="error" />
            </ListItemIcon>
            {isExpanded && <ListItemText sx={{ color: "red" }} primary={"Logout"} />}
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          transition: 'margin-left 0.3s',
          marginLeft: isExpanded ? `${drawerWidth - miniDrawerWidth}px` : 0,
        }}
      >
        <Toolbar />
        {body}
      </Box>
    </Box>
  );
}
