import React, { useState, useEffect } from 'react';
import Layout from "../../../layout/layout";
import { Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "../../../api/axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format, set } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

function CreateMenuPage() {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());

    const [breakfastList, setBreakfastList] = useState([]);
    const [selectedBreakfast, setSelectedBreakfast] = useState([]);
    const [mealList, setMealList] = useState([]);
    const [selectedMeal, setSelectedMeal] = useState([]);
    const [snacksList, setSnacksList] = useState([]);
    const [selectedSnacks, setSelectedSnacks] = useState([]);
    const [sweetList, setSweetList] = useState([]);
    const [selectedSweet, setSelectedSweet] = useState([]);

    useEffect(() => {
        setLoading(true);
        try {


            // Continue with fetching dishes...
            axios.get("dishes/").then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    let breakfastList = response.data.filter(item => item.category === 'BR');
                    let mealsList = response.data.filter(item => item.category === 'ML');
                    let sweetsList = response.data.filter(item => item.category === 'SW');
                    let snacksList = response.data.filter(item => item.category === 'SN');

                    setBreakfastList(breakfastList);
                    setMealList(mealsList);
                    setSweetList(sweetsList);
                    setSnacksList(snacksList);

                } else {
                    console.log("Something went wrong");
                }
                const formattedDate = format(date, "yyyy-MM-dd");
                axios.post("menu/get/", { date: formattedDate })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data);

                            // Save the menu items to state
                            if (response.data.Breakfast) {
                                setSelectedBreakfast(response.data.Breakfast);
                            }
                            if (response.data.Meal) {
                                setSelectedMeal(response.data.Meal);
                            }
                            if (response.data.Snack) {
                                setSelectedSnacks(response.data.Snack);
                            }
                            if (response.data.Sweet) {
                                setSelectedSweet(response.data.Sweet);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching menu for date:", error);
                    });

                setLoading(false);
            });


        } catch (err) {
            console.log(err);

            setLoading(false);
        }
    }, []);

    const SelectableTable = ({ items, selectedItems, setSelectedItems }) => {
        // Helper function to check if an item is selected by ID
        const isItemSelected = (item) => {
            return selectedItems.some(selected => selected.id === item.id);
        };

        const handleSelect = (item) => {
            if (isItemSelected(item)) {
                setSelectedItems(selectedItems.filter(i => i.id !== item.id));
            } else {
                setSelectedItems([...selectedItems, item]);
            }
        };

        return (
            <TableContainer
                sx={{
                    maxHeight: 400,
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                }}
            >
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table stickyHeader>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected(item)}
                                            onChange={() => handleSelect(item)}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <img src={process.env.REACT_APP_MEDIA_URL + item.photo} alt="preview" style={{ height: '50px' }} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        );
    }

    const DraggableList = ({ items, setItems }) => {
        const handleOnDragEnd = (result) => {
            if (!result.destination) return;
            const itemsCopy = Array.from(items);
            const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
            itemsCopy.splice(result.destination.index, 0, reorderedItem);

            setItems(itemsCopy);
        };

        const handleDelete = (id) => {
            setItems(items.filter(item => item.id !== id));
        };

        return (
            <Box
                // sx={{
                // minHeight: 100,
                // maxHeight: 250,
                // overflow: 'auto',
                // border: '1px solid #e0e0e0',
                // borderRadius: 1,
                // '&::-webkit-scrollbar': {
                //     width: '8px',
                // },
                // '&::-webkit-scrollbar-track': {
                //     backgroundColor: '#f1f1f1',
                // },
                // '&::-webkit-scrollbar-thumb': {
                //     backgroundColor: '#888',
                //     borderRadius: '4px',
                // },
                // '&::-webkit-scrollbar-thumb:hover': {
                //     backgroundColor: '#555',
                // },
                // }}
            >
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="items">
                            {(provided) => (
                                <List {...provided.droppableProps} ref={provided.innerRef}>
                                    {items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                            {(provided) => (
                                                <ListItem
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    secondaryAction={
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={() => handleDelete(item.id)}
                                                            sx={{ color: 'error.main' }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText primary={`${index + 1}. ${item.name}`} />
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </Box>
        );
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
        setLoading(true);

        const formattedDate = format(newValue, "yyyy-MM-dd");

        // First fetch all dishes
        axios.get("dishes/")
            .then((response) => {
                if (response.status === 200) {
                    const breakfastList = response.data.filter(item => item.category === 'BR');
                    const mealsList = response.data.filter(item => item.category === 'ML');
                    const sweetsList = response.data.filter(item => item.category === 'SW');
                    const snacksList = response.data.filter(item => item.category === 'SN');

                    setBreakfastList(breakfastList);
                    setMealList(mealsList);
                    setSweetList(sweetsList);
                    setSnacksList(snacksList);

                    // Then fetch menu for the selected date
                    axios.post("menu/get/", { date: formattedDate })
                        .then((menuResponse) => {
                            if (menuResponse.status === 200) {
                                // Save the menu items to state
                                setSelectedBreakfast(menuResponse.data.Breakfast || []);
                                setSelectedMeal(menuResponse.data.Meal || []);
                                setSelectedSnacks(menuResponse.data.Snack || []);
                                setSelectedSweet(menuResponse.data.Sweet || []);
                            }
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching menu for date:", error);
                            // Clear menu selections on error
                            setSelectedBreakfast([]);
                            setSelectedMeal([]);
                            setSelectedSnacks([]);
                            setSelectedSweet([]);
                            setLoading(false);
                        });
                } else {
                    console.log("Something went wrong fetching dishes");
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching dishes:", error);
                setLoading(false);
            });
    }

    const handleSubmit = () => {
        const service_date = format(date, "yyyy-MM-dd");
        let combined = [...selectedBreakfast, ...selectedMeal, ...selectedSnacks, ...selectedSweet];

        let menu = combined.map((item, index) => {
            return {
                'dish_id': item.id,
                'order': index + 1
            }
        });
        console.log(menu);
        const data = {
            date: service_date,
            menu: menu
        };
        try {
            axios.post("menu/create/", data)
                .then(response => {
                    if (response.status === 201) {
                        alert('Success!');
                    } else {
                        alert('Error!');
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                    alert('Error!');
                });
        }
        catch (error) {
            console.log(error);
            alert('Error!');
        }
    }
    const body = (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" sx={{ flexGrow: 1 }}>Create Menu</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        label="Date"
                        value={date}
                        onChange={handleDateChange}
                        renderInput={(props) => <TextField {...props} sx={{ width: 200, mr: 2 }} />}
                    />
                </LocalizationProvider>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}>
                    Submit Menu
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Breakfasts</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={breakfastList}
                                selectedItems={selectedBreakfast}
                                setSelectedItems={setSelectedBreakfast}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Meals</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={mealList}
                                selectedItems={selectedMeal}
                                setSelectedItems={setSelectedMeal}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Snacks</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={snacksList}
                                selectedItems={selectedSnacks}
                                setSelectedItems={setSelectedSnacks}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography>Sweets</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SelectableTable
                                items={sweetList}
                                selectedItems={selectedSweet}
                                setSelectedItems={setSelectedSweet}
                            />
                        </AccordionDetails>
                    </Accordion>

                </Grid>
                <Grid item xs={6}>
                    <h3>Breakfasts</h3>
                    <DraggableList items={selectedBreakfast} setItems={setSelectedBreakfast} />
                    <h3>Meals</h3>
                    <DraggableList items={selectedMeal} setItems={setSelectedMeal} />
                    <h3>Snacks</h3>
                    <DraggableList items={selectedSnacks} setItems={setSelectedSnacks} />
                    <h3>Sweets</h3>
                    <DraggableList items={selectedSweet} setItems={setSelectedSweet} />
                </Grid>
                </Grid>
            )}
        </div>
    );
    return <Layout body={body} title={"Menu"} />;
}
export default CreateMenuPage;